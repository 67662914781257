<template>
    <div class="page_box">
        <div class="top_box">
            <div class="top_title">搜索栏</div>
            <div class="top_flex">
                <div class="search_flex">
                    <div class="texts">关键字</div>
                    <div class="inputs_box">
                        <!-- <input type="text" placeholder="请输入名称" v-model="forms.keyword"> -->
                        <el-input clearable type="text" placeholder="请输入名称" v-model="forms.keyword" />
                    </div>
                </div>
                <div class="search_flex">
                    <div class="texts">签订时间</div>
                    <div class="inputs_box">
                        <el-date-picker clearable v-model="forms.s_time" type="date" placeholder="请选择签订时间"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" >
                        </el-date-picker>
                        <!-- :picker-options="pickerOptions_s_time" -->
                    </div>
                </div>
                <div class="search_flex">
                    <div class="texts">到期时间</div>
                    <div class="inputs_box">
                        <el-date-picker clearable v-model="forms.e_time" type="date" placeholder="请选择到期时间"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" >
                        </el-date-picker>
                        <!-- :picker-options="pickerOptions_e_time" -->
                    </div>
                </div>
                <div class="search_flex">
                     <div class="btn_ends" @click="handle_search">搜索</div>
                </div>
            </div>
          
        </div>
        <div class="bom_box">
            <div class="bom_btn_flex">
                <div class="lefts">
                    {{ page_title }}
                </div>
                <div class="rights">
                    <div class="btns" @click="handle_adds">
                        新增合同
                    </div>
                    <!-- <div class="btns">
                        批量导入
                    </div> -->
                </div>
            </div>
            <div class="bom_box_list">
                <el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"   @selection-change="handle_list_change" v-loading="loading">
                    <!-- 暂时无需 -->
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column prop="name" label="姓名" width="130"></el-table-column>
                    <el-table-column prop="depart_title" label="部门" width="200"></el-table-column>
                    <el-table-column prop="level6" label="岗位" width="200"></el-table-column>
                    <el-table-column prop="mobile" label="手机号" width="200"></el-table-column>
                    <el-table-column prop="id_type" label="证件类型" width="150"></el-table-column>
                    <el-table-column prop="id_card" label="证件号码" width="250"></el-table-column>
                    <el-table-column prop="created_at" label="合同" width="180">
                        <template slot-scope="scope">
                             <div class="scope_ht_box">
                                <div class="imgs">
                                    <img src="../../assets/img/employee/ht.png" alt="">
                                </div>
                                <div class="text" @click="handleCommand({key: 2, row: scope.row})">合同文件</div>
                             </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="s_time" label="签订时间" width="180"></el-table-column>
                    <el-table-column prop="e_time" label="到期时间" width="200">
                        <template slot-scope="scope">
                             <div class="daoqi_time">
                                <div class="time_text">{{ scope.row.e_time }}</div>
                                <div class="daoqi_texts_colo1" v-if="e_time_tx[scope.$index]==1">即将到期</div>
                                <div class="daoqi_texts_colo2" v-if="e_time_tx[scope.$index]==2">已到期</div>
                             </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="更新时间"></el-table-column>
                     <!-- 操作模块   -->
                     <el-table-column label="操作" fixed="right"  width="100">
                        <template slot-scope="scope">
                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link">
                                    <div class="btn_img">
                                        <img src="../../assets/img/employee/caozuo.png" alt="">
                                    </div>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <!-- <el-dropdown-item :command="{ key: 1, row: scope.row }">全部合同</el-dropdown-item> -->
                                    <el-dropdown-item :command="{ key: 1, row: scope.row }">查看合同</el-dropdown-item>
                                    <el-dropdown-item :command="{ key: 3, row: scope.row }">编辑合同</el-dropdown-item>
                                    <el-dropdown-item :command="{ key: 4, row: scope.row }">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pages">
                    <page :pageson='count' @row="row" @currentPage="currentPage"></page>
                </div>
            </div>
        </div>
  
        <Contract_management_add ref="Contract_management_add" @handle_get_list="handle_get_list"></Contract_management_add>

        <Contract_management_pdf ref="Contract_management_pdf"></Contract_management_pdf>
    </div>
</template>
<script>
import page from "@/components/page/page.vue"; //分页  统一的
import Contract_management_add from "@/components/Contract_management/Contract_management_add.vue"; //新增合同
import Contract_management_pdf from "@/components/Contract_management/Contract_management_pdf.vue"; //合同w文件



export default {
    components: {
        page,
        Contract_management_add,
        Contract_management_pdf
        
    

    },
    
    data() {
        let that=this;

        return {
            loading:false,//加载总
            page_title:'',//页面名称  来之路由
            check_list:[],//选中的
            //提交数据
            forms: {
                keyword: '',
                s_time:'',
                e_time:'',
            },
            list: [
               
            ],
            pages:{
                page:1,
                limit:10,
            },
            count:0,
            times:[],//时间范围
            item_row:{},//选中的
            pickerOptions_s_time:{
                disabledDate(time) {
                    console.log('time',time.getTime() ,Date.now())
                    if(that.forms.e_time){
                        return time.getTime() > Date.now(that.forms.e_time);
                    }else{
                        return time.getTime() > Date.now();
                    }
                },
            },
            pickerOptions_e_time:{
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            }

        };
    },

    methods: {
        //接受条数
        currentPage(page) {
            console.log(page)
            this.pages.page = page
            this.get_list()
        },
        // 接收条数
        row(row){
            console.log(row)
            this.pages.limit = row
            this.get_list()
        },
        //选择
        handle_list_change(e){
            console.log('多选',e)
            this.check_list=e
        },
        //搜素
        handle_search(){
            this.pages.page=1
            this.get_list()
        },
        handle_adds(){
            this.$refs.Contract_management_add.handle_open()
        },
        //表单回调 触发重新渲染数据
        handle_get_list(){
            this.get_list()
        },
        //统一的列表接口
        get_list(){
            console.log(this.forms)
            this.loading=true
            this.$api("getUserContractList", {
                // mobile:this.mobile
                ...this.forms,
                ...this.pages,
            }, "get").then((res) => {
                this.loading=false
                if (res.code ==200) {
                    this.count=res.data.count
                    this.list=res.data.list
                }else{
                    alertErr(res.msg)
                }
            });
        },
        //操作回调
        handleCommand(e) {
            console.log(e)
            this.item_row = e.row
            let i = e.key
            switch (i) {
                //全部合同
                case 1:
                    // this.handle_ckht()
                    this.$router.push({
                        path:'/Contract_management_user',
                        query:{
                            // name:this.item_row.name,
                            user_id:this.item_row.user_id,
                            // mobile:this.item_row.mobile,
                            // id_card:this.item_row.id_card,
                        }
                    })
                    break;
                //查看合同
                case 2:
                    this.handle_ckht()
                    break;
                // 编辑合同
                case 3:
                    this.handle_bjht()
                    break;
                // 删除
                case 4:
                    this.handle_sc()
                    break;
              
            }
        },
        //查看合同
        handle_ckht(){
            this.$refs.Contract_management_pdf.handle_open(this.item_row)

        },
        //编辑合同
        handle_bjht(){
            this.$refs.Contract_management_add.handle_open(this.item_row)

        },
        //删除
        handle_sc(){
            this.$confirm('是否删除改信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                this.$api("delUserContract", {
                    id:this.item_row.id
                }, "post").then((res) => {
                    this.loading = false
                    if (res.code == 200) {
                        this.handle_get_list()
                    } else {
                        alertErr(res.msg)
                    }
                });
            }).catch(() => {
                         
            });
        },
    },
    computed: {
        e_time_tx(){
            let lists=[]
            let date1=Date.parse(new Date()) //当前时间

            this.list.forEach((e)=>{
                let i=0
                let date2=Date.parse(e.e_time+" 23:59:59") //到期时间
                if(date2-date1<(86400000*2)&&(date2-date1>0)){
                    i=1
                }else if(date1>date2){
                    i=2
                }
                // if(date1<date2){
                //     i=1
                // }else if(date2-date1>86400000){
                //     i=2
                // }
                lists.push(i)
            })
            // console.log('计算',lists)
            return lists
        }
    },
    watch: {
    },
    created() {
        this.page_title=this.$route.meta.title
        this.get_list()
    },
    mounted() {

    }


};
</script>

<style scoped lang="less">
.page_box {
    height: 100%;

    // background-color: red;
    .top_box {
        width: 100%;
        // height: 80px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        padding-bottom: 29px;
        .top_title{
            padding: 10px 17px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 14px;
            color: #333333;
        }
        .top_flex{
            display: flex;
            align-items: center;
            // justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 77px;
            .search_flex {
                // width: 25%;
                display: flex;
                align-items: center;
                padding-right: 48px;

                .texts {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    white-space: nowrap;
                    padding-right: 12px;
                }
                .inputs_box {
                    flex: 1;
                    input {
                        width: 236px;
                        height: 40px;
                        background: #FFFFFF;
                        border-radius: 0px 0px 0px 0px;
                        border: 1px solid #E8E8E8;
                        padding: 0 9px;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #747474;

                    }
                }
                

               .btn_ends{
                    width: 80px;
                    height: 40px;
                    background: linear-gradient( 90deg, #157DE9 0%, #3697FD 100%);
                    border-radius: 2px 2px 2px 2px;
                    text-align: center;
                    line-height: 40px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    cursor: pointer;
                    &:hover{
                        background: linear-gradient( 90deg, #0564ca 0%, #3697FD 100%);

                    }
               }

            }
        }
   
 


    }

    .bom_box {
        background: #FFFFFF;

        .bom_btn_flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 51px;
            background: #FFFFFF;
            box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
            border-radius: 4px 4px 4px 4px;
            margin-top: 20px;
            padding: 0 20px;

            .lefts {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 14px;
                color: #333333;
            }

            .rights {
                display: flex;
                align-items: center;

                .btns {
                    background: linear-gradient( 90deg, #157DE9 0%, #3697FD 100%);
                    padding: 6px 13px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    cursor: pointer;
                    margin-left: 17px;
                    &:hover{
                        background: linear-gradient( 90deg, #0564ca 0%, #3697FD 100%);

                    }
                }

            }
        }

        .bom_box_list {
            width: 1640px;
            // height: 670px;
            padding: 20px 20px 32px 20px;
            .pages{
                padding-top: 30px;
            }
        }

    }
}
</style>